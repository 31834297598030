module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"uvcorp-www","short_name":"starter","start_url":"/","background_color":"#007bff","theme_color":"#fff","theme_color2":"#007bff12","display":"minimal-ui","icon":"/codebuild/output/src925330476/src/uvision-corp/static/images/dptlogo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"0ae0969b71012bf5c19975475ec69e56"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-332736-1","anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"https://uvision.co/","head":false,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-source-uv-corp-www-news/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-source-uv-corp-www-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"blue","showSpinner":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---plugins-gatsby-source-uv-corp-www-blog-src-pages-blog-js": () => import("./../../../plugins/gatsby-source-uv-corp-www-blog/src/pages/blog.js" /* webpackChunkName: "component---plugins-gatsby-source-uv-corp-www-blog-src-pages-blog-js" */),
  "component---plugins-gatsby-source-uv-corp-www-blog-src-templates-blog-template-js": () => import("./../../../plugins/gatsby-source-uv-corp-www-blog/src/templates/blogTemplate.js" /* webpackChunkName: "component---plugins-gatsby-source-uv-corp-www-blog-src-templates-blog-template-js" */),
  "component---plugins-gatsby-source-uv-corp-www-news-src-pages-news-js": () => import("./../../../plugins/gatsby-source-uv-corp-www-news/src/pages/news.js" /* webpackChunkName: "component---plugins-gatsby-source-uv-corp-www-news-src-pages-news-js" */),
  "component---plugins-gatsby-source-uv-corp-www-news-src-templates-news-template-js": () => import("./../../../plugins/gatsby-source-uv-corp-www-news/src/templates/newsTemplate.js" /* webpackChunkName: "component---plugins-gatsby-source-uv-corp-www-news-src-templates-news-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-why-js": () => import("./../../../src/pages/about/why.js" /* webpackChunkName: "component---src-pages-about-why-js" */),
  "component---src-pages-capabilities-index-js": () => import("./../../../src/pages/capabilities/index.js" /* webpackChunkName: "component---src-pages-capabilities-index-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-index-js": () => import("./../../../src/pages/labs/index.js" /* webpackChunkName: "component---src-pages-labs-index-js" */),
  "component---src-pages-lets-talk-index-js": () => import("./../../../src/pages/lets-talk/index.js" /* webpackChunkName: "component---src-pages-lets-talk-index-js" */),
  "component---src-pages-lets-talk-partners-js": () => import("./../../../src/pages/lets-talk/partners.js" /* webpackChunkName: "component---src-pages-lets-talk-partners-js" */),
  "component---src-pages-lets-talk-providers-js": () => import("./../../../src/pages/lets-talk/providers.js" /* webpackChunkName: "component---src-pages-lets-talk-providers-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-problems-js": () => import("./../../../src/pages/problems.js" /* webpackChunkName: "component---src-pages-problems-js" */),
  "component---src-pages-request-assessment-js": () => import("./../../../src/pages/request-assessment.js" /* webpackChunkName: "component---src-pages-request-assessment-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-solution-js": () => import("./../../../src/pages/solution.js" /* webpackChunkName: "component---src-pages-solution-js" */),
  "component---src-pages-solutions-business-js": () => import("./../../../src/pages/solutions/business.js" /* webpackChunkName: "component---src-pages-solutions-business-js" */),
  "component---src-pages-solutions-index-js": () => import("./../../../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-solutions-partners-js": () => import("./../../../src/pages/solutions/partners.js" /* webpackChunkName: "component---src-pages-solutions-partners-js" */),
  "component---src-pages-solutions-people-js": () => import("./../../../src/pages/solutions/people.js" /* webpackChunkName: "component---src-pages-solutions-people-js" */),
  "component---src-pages-solutions-providers-js": () => import("./../../../src/pages/solutions/providers.js" /* webpackChunkName: "component---src-pages-solutions-providers-js" */),
  "component---src-pages-solutions-startups-js": () => import("./../../../src/pages/solutions/startups.js" /* webpackChunkName: "component---src-pages-solutions-startups-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

